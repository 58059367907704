.home {
  height: 100%;
  position: relative;

  > .container {
    height: 100%;
    display: grid;
    gap: 1.7rem;
    grid-template-columns: 80px repeat(2, 1fr);
  }
}

.confirm {
  display: flex;
  height: 100%;
  flex-direction: column;

  justify-content: space-evenly;
  align-items: center;
  padding: 0 30px;

  > h3 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin: 0;

    > span {
      font-weight: 400;
      /* text-decoration: underline; */
    }
  }

  > h1 {
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 0.1rem;
    text-align: center;
    margin: 0;
  }

  > img {
    max-width: 90%;
  }
}
