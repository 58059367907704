.splash {
  width: 100%;
  height: 100%;
  position: absolute;

  > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 0px 50px;

    > button {
      position: absolute;
      height: 200px;
      width: 200px;
      right: -28px;
      bottom: -28px;
      border-radius: 50%;
      cursor: pointer;
      border: none;
      animation: showButton 2s ease-in-out forwards;
      background-color: var(--tertiary-500);
      padding: 0px;

      > span {
        color: var(--secondary-500);
        font-size: 60px;
        font-weight: 200;
        animation: showText 2s ease-in-out forwards;
      }
    }

    &.go {
      .quote-container {
        animation: hideText 1s ease-in-out forwards;
      }
      > button {
        animation: zoomIn 2s 0.3s ease-in-out forwards;
        > span {
          animation: hideText 1s ease-in-out forwards;
        }
      }
    }
  }
}

@keyframes showButton {
  from {
    height: 0px;
    width: 0px;
    right: 0;
    bottom: 0;
  }
  to {
    height: 200px;
    width: 200px;
    right: -28px;
    bottom: -28px;
    animation: none;
  }
}

@keyframes showText {
  0%,
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideText {
  to {
    opacity: 0;
  }
}

@keyframes zoomIn {
  50% {
    transform: scale(20);
  }
  100% {
    transform: translate3d(calc(-100dvw - 28px), calc(-100dvh - 28px), 0px);
  }
}
