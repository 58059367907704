.calendar {
  display: grid;
  gap: 15px;
  overflow-y: scroll;
  padding-bottom: 75px;
  position: relative;

  > .stickyMonth {
    position: sticky;
    top: 0px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 0px 10px 10px 0px;
    background-color: #2c2c2c;
    margin-bottom: -14px;
    color: #fff;
    width: 75%;
    border: 1px solid #fff;
    border-left: none;
    font-weight: 600;
    font-size: 18px;
  }

  > .endFade {
    position: fixed;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 75px;
    bottom: 0px;
    width: 81px;
    z-index: 1;
  }

  > .day {
    position: relative;
    height: 75px;
    width: 100%;
    color: #2c2c2c;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .dot {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid white;
      background-color: var(--primary-500);
      top: -1px;
      right: -1px;
    }

    > .date {
      position: absolute;
      top: 12px;
      font-size: 30px;
      font-weight: 500;
    }
    > .month {
      position: absolute;
      bottom: 12px;
      font-size: 12px;
      font-weight: 400;
    }

    &.selected {
      background-color: #2c2c2c;
      color: #fff;
    }
    &.disabled {
      background-color: #fafafa99;
      color: #a2a2a299;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
