.header {
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr;
  padding: 30px 15px 20px;
  align-items: center;
  justify-content: center;
  row-gap: 20px;

  > img {
    justify-self: center;
    max-width: 70%;
    max-height: 100%;
  }
  > label {
    font-size: 14px;
    text-align: center;
  }
}
