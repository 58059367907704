.card {
  /* background: #d3d3d3; */
  /* background: linear-gradient(to top left, #fff, ); */
  background: var(--tertiary-500);
  border-radius: 20px;
  /* height: 100%; */
  position: absolute;
  left: 0;
  right: 10px;
  transition: all 500ms ease-in-out forwards;
  transform-origin: bottom right;

  > .content {
    position: relative;
    padding: 20px;
    padding-bottom: 40px;
    display: grid;
    gap: 40px;

    > button {
      position: absolute;
      border-radius: 50%;
      background-color: var(--secondary-500);
      border: none;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 5px 21px -5px rgba(0, 0, 0, 1);
      -webkit-box-shadow: 1px 5px 21px -5px rgba(0, 0, 0, 1);
      -moz-box-shadow: 1px 5px 21px -5px rgba(0, 0, 0, 1);
      bottom: -30px;
      color: var(--tertiary-500);
      transition: all 100ms ease-in;

      &.prev {
        right: 100px;
      }

      &.next {
        right: 10px;
      }

      &:disabled {
        background-color: #fafafa;
        color: #a2a2a2;
        box-shadow: none;
      }
    }
  }

  &[data-active="true"] {
    z-index: 2;

    &.animate {
      animation: inactiveAnimate 1s ease-in-out forwards;
    }
  }
  &[data-active="false"] {
    transform: rotate(-10deg);
    z-index: -1;
    opacity: 0.4;

    &.animate {
      animation: activeAnimate 1s ease-in-out forwards;
    }

    &[data-current-step="0"] {
      &[data-step="1"] {
        opacity: 0.4;
      }

      &[data-step="2"] {
        opacity: 0;
        animation: activeAnimate0Opactity 1s ease-in-out forwards;
      }
    }
    &[data-current-step="1"] {
      &[data-step="0"] {
        opacity: 0.4;
      }

      &[data-step="2"] {
        opacity: 0;
        animation: activeAnimate0Opactity 1s ease-in-out forwards;
      }
    }
    &[data-current-step="2"] {
      &[data-step="1"] {
        opacity: 0.4;
      }

      &[data-step="0"] {
        opacity: 0;
        animation: activeAnimate0Opactity 1s ease-in-out forwards;
      }
    }

    > .content {
      > button {
        opacity: 0;
      }
    }
  }
}

@keyframes activeAnimate {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }
  50% {
    transform: rotate(90deg) scale(0.8);
    opacity: 0.7;
    z-index: 0;
  }
  100% {
    transform: rotate(-10deg) scale(1);
    opacity: 0.4;
    z-index: 0;
  }
}
@keyframes activeAnimate0Opactity {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 0;
    z-index: 0;
  }
  50% {
    transform: rotate(90deg) scale(0.8);
    opacity: 0.7;
    z-index: 0;
  }
  100% {
    transform: rotate(-10deg) scale(1);
    opacity: 0;
  }
}
@keyframes inactiveAnimate {
  0% {
    transform: rotate(-10deg) scale(1);
    opacity: 0.5;
    z-index: 0;
  }
  50% {
    transform: rotate(-5deg) scale(1.1);
    opacity: 1;
    z-index: 2;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}
