.input-view {
  display: flex;
  flex-direction: column;
  gap: 5px;

  > input {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #2c2c2c;
    outline: none;
    font-size: 16px; /*to disable zoom on ios*/
    font-weight: 600;

    &::placeholder {
      color: #2c2c2c;
      opacity: 0.3; /* Firefox */
      font-weight: 500;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: #333;
    }

    &.update {
      border-bottom: none;
      user-select: none;
    }
  }

  > .message {
    font-size: 9.5px;
  }

  > .chip-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    > .chip {
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      border: 1px solid #2c2c2c;
      border-radius: 10px;
      font-weight: 600;

      &.active {
        background-color: var(--primary-500);
        border-color: var(--primary-500);
        color: #fff;
      }

      &.disabled {
        border-color: #a2a2a299;
        color: #a2a2a299;
      }

      > input {
        display: none;
      }
    }
  }
}
