.section {
  display: grid;
  gap: 10px;

  > h1 {
    font-size: 22px;
    line-height: 0px;
    color: var(--secondary-500);
    /* color: #c06669; */
  }
  > .children {
    display: grid;
    gap: 20px;

    > h2 {
      line-height: 0px;
      font-size: 18px;
      font-weight: 500;
    }

    > .resend {
      border-radius: 50px;
      background-color: var(--secondary-500);
      border: none;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--tertiary-500);
      transition: all 100ms ease-in;

      &:disabled {
        width: 150px;
        background-color: #fafafa;
        color: #a2a2a2;
      }
    }

    > .submit {
      border-radius: 10px;
      background-color: var(--secondary-500);
      border: none;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--tertiary-500);
      font-size: 20px;
    }
  }
}

.carousel {
  display: flex;
  height: 100%;
  width: 100%;

  > .carousel_wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    > .carousel_card {
      display: flex;
      position: absolute;
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s ease-in-out;

      &.active {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
}

.quote-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  > .quote-area {
    display: flex;
    justify-content: center;
    gap: 5px;
    font-family: "Dancing Script", cursive;
    padding: 0px 10px 0px 30px;
    font-size: 20px;
    position: relative;
    z-index: 1;
    transition: all 0.1s;

    > svg {
      position: absolute;
      left: 10px;
      opacity: 0.5;
      color: grey;
    }
  }
  > .author {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0px 20px;
    width: 100%;
    font-size: 10px;
    color: grey;
  }
}

.social {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 0px 10px 20px;

  > .icons-box {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}

.disabledModal {
  display: none;

  > .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  > .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  > .lds-ripple div:nth-child(2) {
    animation-delay: -1s;
  }

  &[open] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    padding: 0px;
    background: rgba(0, 0, 0, 0.3);
  }
}

.modal {
  border: none;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  padding: 0px;
  position: relative;

  & a {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  > .close {
    position: absolute;
    top: 10px;
    right: 10px;

    > button {
      background-color: transparent;
      border: none;
      height: 40px;
      width: 40px;
      color: #2c2c2c;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      > span {
        font-size: 35px;
      }
    }
  }

  &[open] {
    animation: show 0.3s ease;
  }

  &.hide {
    animation: hide 0.3s ease;
  }
}

@keyframes show {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes hide {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
